<template>
  <div>
    <el-button @click="load">查看关联奖励</el-button>
    <el-dialog append-to-body title="订单关联奖励" :visible.sync="dialogTableVisible">
      <el-table v-loading="loading" :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="uid" label="用户编号"></el-table-column>
        <el-table-column prop="u_nickname" label="用户昵称"></el-table-column>
        <el-table-column prop="u_phone" label="用户手机"></el-table-column>
        <el-table-column prop="fee" label="金额"></el-table-column>
        <el-table-column prop="log" label="记录"></el-table-column>
        <el-table-column prop="type" :formatter="awardType2Tag" label="奖励类型"></el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {awardType2Tag} from "@/common/mod/award";

export default {
  name: "OrderAward",
  props:{
    order_id:{
      type:Number,
      default:0,
    }
  },
  data(){
    return{
      dialogTableVisible:false,
      loading:false,
      list:[],
      total:0,
      page:1,
    }
  },
  methods:{
    awardType2Tag(e){
      return awardType2Tag(e.type)
    },
    load(){
      this.loading = true;
      this.dialogTableVisible = true;
      this.$u.api.card.orderAwardSearch({
        page:this.page,
        order_id:this.order_id,
      }).then(res=>{
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
  }
}
</script>

<style scoped>

</style>